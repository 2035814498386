<template>
  <div class="pa-4">
    <v-card>
      <v-card-title class="text-capitalize">{{ name }}</v-card-title>
      <v-card-text>
        <v-layout row wrap>
          <v-flex xs4 class="pa-2">
            <CardProperty label="Nom" :value="user.usr_lastName" />
            <CardProperty label="Prénom" :value="user.usr_firstName" />
            <CardProperty label="Username" :value="user.usr_username" />
            <CardProperty label="Rôle" :value="user.usr_role" />
          </v-flex>
          <v-flex xs4 class="pa-2">
            <CardProperty label="Téléphone" :value="user.usr_phone" />
            <CardProperty label="Mobile" :value="user.usr_mobile" />
            <CardProperty label="Email" :value="user.usr_email" />
          </v-flex>
        </v-layout>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import CardProperty from '@/components/ui/CardProperty'

export default {
  components: {
    CardProperty
  },
  props: {
    user: {
      type: Object,
      required: true
    }
  },
  computed: {
    name () {
      return `${this.user.usr_firstName} ${this.user.usr_lastName}`.trim()
    }
  }
}
</script>
