<template>
  <div>
    <UserCard :user="user" />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import UserCard from '@/components/users/UserCard'

export default {
  components: { UserCard },
  computed: {
    ...mapState({
      user: state => state.user.user
    })
  },
}
</script>
