<template>
  <div>
    <div class="font-weight-bold">{{ label }}</div>
    <div>{{ value || '&nbsp;' }}</div>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      required: true
    },
    value: {
      type: String,
      required: false,
      default: () => ''
    }
  }
}
</script>
